.paymentDocs {

    .small-container {
        width: 30%;
    }

    .input-select {
        margin-right: 6px;

        &:last-child {
            width: 30%;
        }
    }

    .chart-container {
       
        .chartjs-render-monitor {
            width: 100% !important;
            height: 100% !important;
            background-color: $white;
            padding: 16px;
        }

        .chart-big {
            padding-right: 8px;
        }
        .bar-chart {
            height: 30% !important;
        }

        .line-chart {
            margin-right: 8px;
        }

        .line{
            height: 85% !important;
        }

        .pie {
            height: 60% !important;
        }
    }
}