.img-column{
    display: flex;
    flex-direction: column;
    // height: 100%;
    position: relative;
    width: 337px;
    height: 180px;
    
    
    button {
        width: 30px;
        align-self: flex-end;
        position: absolute;
        z-index: 2;
        // top: 0;
        bottom: 8px;
        right: 0;
        i{
            border-radius: 50%;
            background: #000;
            color: $primary;
            font-size: 40px;
        }
    }
    .container-img{
        z-index: 1;
        label{
            text-align: center;
        }
    }
}
.responsive-img{
    display: flex;
    align-items: center;
}

.form-column {
	width: 157%;
}

.images-ine {

	
	.img-column{
		display: flex;
		flex-direction: column;
		position: relative;
		width: 320px;
		height: 150px;
		margin-bottom: 8px;
		
		button {
			width: 38px;
			align-self: flex-end;
			position: absolute;
			z-index: 2;
			bottom: 0px;
    		right: 16px;
			i{
				border-radius: 50%;
				background: #3a3a3a;
				color: white;
				padding: 8px;
				font-size: 16px;
			}
		}
		.container-img{
			display: flex;
			justify-content: center;
			background: #eee;
			z-index: 1;
			position: relative;
			width: 95%;
			max-width: 320px;
			min-width: auto;
			justify-content: center;
			label{
				text-align: center;
			}
		}
		.img-duo-preview {
			min-height: 152px;
			max-height: 152px;
			//width: 100%;
			object-fit: fill;
		}

		.btn-upload-image {
			width: 100%;
			height: 165px;
			cursor: pointer;
		}
	}

	.img-width-small {
		width: 250px;
		padding-left: 24px;
	}
}


.logo-container-error{
	display: flex;
	justify-content: center;
	align-items: center;
}

.scroll{
	overflow-y: auto;
    height: 300px;
}

.invisible{
	display: none;
	background-color: red !important;
}

.space-8 {
	padding-left: 8px;
}

.space-16 {
	padding-left: 16px;
}

.space-24 {
	padding-left: 24px;
}

.space-32 {
	padding-left: 32px;
}

.react-phone-number-input__input--style {
	padding: 0 10px;
	font-size: 0.93rem !important;
}

.react-phone-number-input__icon {
	width: calc(0.31em * 5);
    height: calc(0.30em * 4);
	border: none;
}

.pad-bot {
	th {
		padding-bottom: 2px;
	}
}

.mini-i {
	font-size: 0.7rem;
}

#table-problem {
  position: static;
}

.width-70 {
	width: 70%;
}

.width-30 {
	width: 30%;
}