.billing {
	.dinamic {
		margin-bottom: 16px;
	}

	.titles-descriptors {
		.label-title-config {
			margin-right: 24px;

			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}

	.billing-rows {
		.label-title-config {
			width: 50%;
		}

		.label-title-descriptors {
			width: 100%;
		}

		.options-descriptors {
			margin-right: 24px;

			&:nth-child(4n) {
				margin-right: 0;
			}
		}

		.label-title {
			width: 24%;
		}

		.label-title-duo {
			width: 61%;
		}

		.label-midle {
			padding: 0px 32px 0px 32px;
			width: 37%;
		}
	}

	.small-row {
		width: 40%;
	}

	.btn-primary {
		margin-right: 8px;
		/*padding: 7px 32px;
		height: 50px;
		min-width: 180px;*/
	}

	.btn-secondary {
		background: #6c757d;
		color: white;
		padding: 7px 32px;
		height: 50px;

		&:hover {
			background-color: #79848d;
			color: white;
		}
	}
}

.column-imp {
	width: 140%;
}

.table-product {
	padding: 8px !important;

	th {
		font-size: 1rem;
	}

	td {
		font-size: 0.95rem;
	}
	.th-options {
		width: 65px !important;
		font-size: 1rem;
	}
}

.table-container {
	max-height: 270px;
	overflow-y: scroll;
}

.table-history-container {
	max-height: 400px;
	overflow-y: scroll;
}

.table-history {
	width: 100%;
	position: relative;
	max-height: 400px;
	border-bottom: 1px solid #eee;

	td {
		border-right: 1px solid #cfcfcf;
	}

	td,
	th {
		//border: 1px solid #e3e3e3;

		text-align: left;
		padding: 0px 16px;
		font-size: 0.93rem;
		font-weight: 500;

		input,
		select {
			background-color: transparent;
		}
	}

	th {
		position: sticky;
		background-color: #e6e6e6;
		//background-color: #e6e6e67a;
		top: 0;
		z-index: 1;
		border-bottom: 1px solid #cfcfcf;
		border-top: 1px solid #cfcfcf;
	}

	.text-right {
		text-align: end;
	}

	.text-left {
		text-align: start;
	}

	.text-center {
		text-align: center;
	}

	.row-space {
		td,
		th {
			padding: 4px 24px;
		}
	}

	.not-ok {
		/*background-color: #ea545f;
		input {
			color: #e3e3e3!important;
		}*/
		background-color: #ea545f2e;
	}

	tr .no-border {
		border-right: none;
	}

	.border-right {
		border-right: 1px solid #cfcfcf;
	}

	.border-left {
		border-left: 1px solid #cfcfcf;
	}

	tfoot {
		td {
			border-bottom: 1px solid #cfcfcf;
		}
	}
}

.table-rules {
	position: relative;
	width: 100%;

	td,
	th {
		border: 1px solid #e3e3e3;
		text-align: left;
		padding: 0px 16px;
		font-size: 0.93rem;
	}

	thead * {
		background-color: #e6e6e6 !important;
		text-height: auto;
	}

	th {
		position: sticky;
		top: 0;
		// z-index: 1;
	}

	.text-right {
		text-align: end;
	}

	.text-left {
		text-align: start;
	}

	.text-center {
		text-align: center;
	}

	.td-transparent {
		background: white;
		border: 0px;
	}

	.row-space {
		td,
		th {
			padding: 4px 24px;
		}
	}

	.space-left {
		padding-left: 32px;
	}

	.row-total {
		td {
			border: 0;
			padding: 4px 24px;
		}
	}

	.td-input {
		width: 15%;
	}

	// thead:nth-child(1) {
	// 	background-color: #e6e6e6;
	// 	padding: 4px 4px;
	// }
}

// table{
//     // margin: 0;
//     // padding:0;
//     // // position: relative;
//     // .th-sticky{
//     //     position: sticky;
//     //     top:0;
//     //     background: white;
//     // }
// }

.table-responsive-table {
	overflow-y: scroll;
	max-height: 500px;
}

.table-responsive-payment {
	overflow-y: scroll;
	max-height: 250px;
}

.table-responsive-sat {
	overflow-y: auto;
	height: 50vh;
	min-height: 300px;
	max-height: 500px;
}

.table-responsive-receipment {
	overflow-y: auto;
	max-height: 400px;

	.col-unidad {
		width: 20%;
	}

	.col-metodo {
		width: 15%;
	}
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	font-weight: bold;
	font-size: 1.1rem !important;
	padding-bottom: 6px !important;
}

.react-datepicker__month .react-datepicker__month-text {
	display: inline-block;
	width: 4rem !important;
	margin: 3px;
	padding: 4px 5px !important;
	font-size: 1rem !important;
	text-transform: capitalize !important;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range {
	border-radius: 0.3rem;
	background-color: #ea545f !important;
	color: #fff;
	transition: all 250ms ease-in-out;

	&:hover {
		background-color: #dedede !important;
		color: black !important;
	}
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
	border-radius: 0.3rem;
	background-color: #ea545f !important;
	color: #fff;
	transition: all 250ms ease-in-out;

	&:hover {
		background-color: #dedede !important;
		color: black !important;
	}
}

.container-check-billing {
	width: 100% !important;

	.checkmark {
		border-radius: 0;
	}
}
.new-search-container {
	display: flex;
	align-items: center;

	.sub-container {
		display: flex;
		align-items: center;
		text-align: center;
		input {
			//background: #e9e9e9;
		}
		i {
			color: black;
		}
	}
	.btn-search {
		text-align: center;
		height: 38px;
		font-size: 1rem;
		// background: lightgray;
		color: black;
	}
}
.viviendas-container-check {
	display: flex;
	flex-wrap: wrap;
	// justify-content: space-between;
	max-height: 280px;
	overflow-y: auto;
	align-items: flex-start;
	align-items: center;
	.viviendas-render {
		width: 20%;
		height: auto;
		display: flex;
		//justify-content: space-between;
		padding-right: 1rem;
		padding-left: 1rem;

		.check {
			display: flex;
			align-items: center;
		}
	}
}
.center-all {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	// align-content: center;
}

// AUXILIARES

.column-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	p {
		// align-self: flex-start;
		margin-top: 3px;
	}
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {
}

/*    NOTEBOOKS, IPADS DEVICES    */

@media screen and (max-width: 1366px) {
	.table-responsive-sat {
		max-height: 500px;
	}
}

/*    NOTEBOOKS, IPADS DEVICES    */

@media screen and (max-width: 992px) {
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
}
