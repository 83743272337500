/**
*   inputs.scss
*   @description: Estilos para los inputs
*/

input,
select,
option,
textarea {
	font-family: $Mukta;
}

select:disabled,
input:disabled {
	background-color: #ebebe4;
}

.input {
	color: $dark;
	padding: $paddingNormal;
	border-bottom: 2px solid rgba($gray, 0.5);
	&:focus {
		border-bottom: 2px solid $purple;
	}
}
.disabled {
	background: red;
}

.input-add {
	position: relative;
	margin-top: 4px;

	input {
		padding-right: 32px;
	}
	.btn-add {
		position: absolute;
		right: 10px;
		top: 4px;
	}
}

.text-big {
	height: 157px;
}
.input-small {
	width: 50% !important;
}
.input-mini {
	width: 45% !important;
}

.input-medium {
	width: 58% !important;
}

.input-huge {
	width: 95% !important;
}
/*-------- Input modales ---------- */

.input-modals {
	width: 100%;
	font-size: 0.9rem;
	padding: 0 10px;
	transition: all 200ms ease-in-out;
}

.input-disabled {
	transition: all 200ms ease-in-out;
	background: #eaeaea;
	width: 100%;
	padding: 2px 10px 2px;
	//font-size: $fontText;
	font-size: 0.9rem;
}

.check-option {
	background-color: #d4d4d4 !important;
	box-shadow: 0px 0px 2px dimgrey;
}

.container-time {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1;

	width: 250px;
	height: 100px;

	.sub-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 2;

		width: 80%;
		height: 55%;

		border-style: groove;
		border-radius: 10px;
		border-color: #000000;
		border-width: 1px;
	}

	label {
		position: absolute;
		z-index: 3;
		top: 0px;
		font-size: 0.95rem;
		background: #fff;
	}
}
/*----------------------------------*/

.search-column {
	position: relative;
	.btn-buscar {
		position: absolute;
		right: 2px;
	}
}
.input-select {
	border-radius: 4px;
	padding: $paddingNormal;
	font-weight: $weightSemi;
}

.incidents .input-select,
.guests .input-select {
	width: 200px;
	margin-left: $marginMedium;
}

.textarea {
	resize: none;
	line-height: 24px;
	padding: $paddingNormal;
	background-color: #f2f2f3;
	border-bottom: 2px solid rgba(159, 159, 159, 0.5);
}

.textarea-small {
	height: 70px;
}

.radio {
	top: 0;
	border: 0;
	height: 20px;
	width: 20px;
	outline: none;
	cursor: pointer;
	border-radius: 50px;
	color: #666666;
	background-color: #f1f1f1;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	display: inline-block;
	position: relative;
	margin-right: $marginMedium;
	transition: all 300ms ease-in-out;
}
.radio:checked::before {
	left: 6px;
	font-size: 0.8rem;
	content: "\02143";
	position: absolute;
	transform: rotate(40deg);
}

.radio:hover {
	background-color: #dedede;
}

.radio:checked {
	color: $white;
	background-color: $primary;
}

.radio-label {
	color: $dark;
	cursor: pointer;
	font-family: $Mukta;
	font-size: $fontSmall;
	margin-right: $marginMedium;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.input-filter {
	padding: $paddingNormal;
	//box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
}

.input-filter-date {
	width: 95%;
}

.input-modals-small {
	width: 40%;
	padding: 4px 2px;
}

.modal-incidents #incident-resolution {
	border-radius: 0;
	background-color: #f2f2f3;
}

.input-year {
	width: 30%;
	padding: 4px $paddingNormal;
	margin-left: $marginMedium;
}
.modal-houses .input {
	width: 100%;
	margin-left: $marginNormal;
}

.input-description {
	border: 1px solid rgb(59, 59, 59);
}

.modal-houses .input-select-type {
	width: 75%;
}

.width-45 {
	width: 45%;
}

.width-30 {
	width: 30%;
}

.input-clean {
	position: relative;
	//height: 42px;
	input {
		padding-right: 25px;
	}
	i {
		height: 100%;
		right: 10px;
		top: 10px;
		position: absolute;
		cursor: pointer;
		z-index: 2;
	}
}

.input-search {
	position: relative;
	//width: 40%;

	.input-filter {
		width: 100%;
		padding: 5px 12px 5px 35px;
		//border: 1px solid #c4c8cb;
	}

	i {
		width: 4%;
		left: 13px;
		top: 10px;
		color: #9e9e9e !important;
		cursor: none;
		font-size: 0.9rem;
		position: absolute;
	}
}

/* The container */
.container-check {
	display: block;
	height: 25px;
	width: 25px;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-radius: 0;
}

/* Hide the browser's default checkbox */
.container-check input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	border-radius: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: $white;
	border-radius: 0 !important;
	border: 1px solid #666666;
}

.checkmark-disabled {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	border-radius: 0 !important;
	border: 1px solid #666666;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
// .container-check input:checked ~ .checkmark {
// 	// background-color: #ea4235;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
	left: 9px;
	top: 5px;
	width: 8px;
	height: 13px;
	border: solid $red;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.input-select-configuration {
	width: 20%;
	padding: 0;
	border-radius: 2px;
	border: 1px solid rgba(159, 159, 159, 0.5);
}

// RADIO BUTTONS

.container-radio {
	display: flex;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 0.93rem;
	line-height: 29px;
	height: 27px;
	align-items: center;
	color: #000000;
	font-weight: bold;
	-webkit-user-select: none;
	user-select: none;
	justify-content: center;
	width: auto;
}

/* Hide the browser's default radio button */
.container-radio input {
	position: absolute;
	opacity: 0;
	transition: all 300ms ease-in-out;
	cursor: pointer;
}

/* Create a custom radio button */
.checkmarks {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmarks {
	background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmarks {
	background-color: #ea545f;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarks:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmarks:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmarks:after {
	top: 5px;
	left: 5px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: white;
	transition: all 300ms ease-in-out;
}

.ct-toast {
	font-family: $Mukta;
	font-size: 1em;
}
.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.label-input-file {
	overflow: hidden;
	width: auto;
	background: $purple;

	color: white;
	margin-left: 1rem;

        &:hover{
            cursor: pointer;
        }
  }

.fa-sort, .fa-sort-down, .fa-sort-up {
  font-size: 0.9rem;
}
