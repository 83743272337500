
$success:       #6c757d;
$successLight:  #6c757d86;
$cancel:        #ea4235;
$cancelLight:   #eb5b51;

$overlay:       rgba(0, 0, 0, 0.5);
$header:        #000000;
$hoverBtnClose: #6c757d86;
$background:    #FFFFFF;
$blanco:    #FFFFFF;

.super-show{
    visibility: visible;
}
.super-hide{
    visibility: hidden;
}

// TAMAÑO MODAL

.small {
    width: 45% !important;
    height: auto !important;
}

.normal {
    width: 50%;
    height: auto;
}

.big {
    width: 57% !important;
    height: auto !important;
}
.huge{
    width: 80%;
    height: auto;
}

.medium {
    width: 75%;
    height: auto;
}

.static-nav {
    width: 70% !important;
    height: 78% !important;
}

@mixin superBotones {
    width: 100px;
	padding: 3px;
    font-size: 0.93rem;
    font-weight: 500;
    color: white;
    background-color: $purple;
	transition: all 300ms ease-in-out;
	i {
		font-size: 1rem;
    }
    border-radius: 5px;
  
    &:hover {
        background-color: rgba($purple, 0.8);
		text-shadow: 0px 0px 6px rgba(255, 255, 255, .4);
		-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
		-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
		transition: all 0.4s ease 0s;
    }  
}

/*.super-modal-container{
    // visibility: hidden;
    position:   fixed;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    z-index: 5;    
    opacity: 1;
    
    // Decoración
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;

    .super-modal{
        align-self: center;
        width: 65%;
        height: auto;
        background: $background;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        .super-header{
            &:hover{
                cursor: pointer;
            }
            background: black;
            width: auto;
            height: 34px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                color: $blanco;
                font-size: 1.2rem;
                margin-right: 1rem;
                margin-left: 1rem;
            }

            i {
                color: $blanco;
                margin-right: 1rem;
                margin-left: 1rem;
                font-size: 0.93rem;
            }
        }
        .super-sub-header{
            background: rgb(142, 142, 142);
            height: 9%;

            display: flex;
            justify-content: space-around;

            .super-item{


                border-style: solid;
                border-width: 1px 2px 2px 0px;
                
                border-color: rgb(250, 250, 250);
                
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 300ms ease-in-out;

                &:last-child{
                    border-width: 1px 0px 2px 0px;
                } 
                p{
                    color: white;
                    font-size: 1.2rem;
                }
                &:hover{
                    background: #ea545f;
                    cursor: pointer;
                }
            }
            
            .super-item-active {
                background: #ea545f;

                &:hover{
                    background: #f25862;
                    cursor: pointer;
                }
            }

            // &:nth-child{
            //     background: red;
            // }
        }
        .super-body{
            display: flex;
            flex-direction: column;
            background: white;
           // height: 75%;
            flex-grow: 2;
        }
        .super-footer{
            background: white;
            height: 80px;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            .super-btn{
                @include superBotones;
                
            }

            .next-container{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                button{
                    @include superBotones;
                }
            }
        }
    }
}*/

//superModal - dinamicModal
.super-modal-container{
    position:   fixed;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    z-index: 5;    
    opacity: 1;
    // Decoración
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;

    .modal-container {
		width: 95%;
	}

    .super-modal{
        align-self: center;
        border-radius: 4px;
        width: 65%;
        height: auto;
        background: $background;
        display: flex;
        flex-direction: column;
        
        .super-header{
            &:hover{
                cursor: pointer;
            }
            //background: #ea545f;
            background-color: $purple;
            border-radius: 4px 4px 0 0;
            width: 100%;
            height: 31px;
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                color: $blanco;
                font-size: 1.1rem;
                font-weight: 500;
            }

            .btn-modal-close {
			    position: absolute;
                right: 18px;
                width: auto;
                //height: 25px;
                //width: 25px;
                //border-radius: 50%;
                //background-color: $header;
                color: white;
                font-size: 0.9rem;
                transition: background-color 400ms ease-in-out;

                &:hover {
                    //color: $primary;
                    //background-color: $successLight;
                }
            }
        }

        .super-sub-header{
            background: rgb(142, 142, 142);
            height: 9%;

            display: flex;
            justify-content: space-around;

            .super-item{


                border-style: solid;
                border-width: 1px 2px 2px 0px;
                
                border-color: rgb(250, 250, 250);
                
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 300ms ease-in-out;

                &:last-child{
                    border-width: 1px 0px 2px 0px;
                } 
                p{
                    color: white;
                    font-size: 1.2rem;
                }
                &:hover{
                    background: #6d2e87;
                    cursor: pointer;
                }
            }
            
            .super-item-active {
                background: #6d2e87;

                &:hover{
                    background: #6d2e87;
                    cursor: pointer;
                }
            }

            // &:nth-child{
            //     background: red;
            // }
        }

        .super-body{
            display: flex;
            flex-direction: column;
            background: white;
            flex-grow: 2;
        }

        .super-footer{
            background: white;
            height: 60px;
            border-radius: 0 0 4px 4px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            /*.super-btn{
                @include superBotones;
            }*/

            .btn-action-success {
                //@include button-action;
                //background-color: $success;
                width: 100px;
                padding: 3px;
                //margin-right: 8px;
                font-size: 0.93rem;
                font-weight: 500;
                color: white;
                transition: all 300ms ease-in-out;
                background-color: $purple;

                &:hover {
                    background-color: rgba($purple, 0.8);
                }
            }

            .btn-action-cancel {
                /*@include button-action;
                background-color: $cancel;*/
                width: 100px;
                padding: 3px;
                margin-right: 8px;
                font-size: 0.93rem;
                font-weight: 500;
                color: $purple;
                transition: all 300ms ease-in-out;
                background-color: #9e9e9e59;

                &:hover {
                    background-color: $cancelHover;
                }
            }

            .next-container{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                button{
                    @include superBotones;
                }
            }
        }
    }
}

.overlay {
	top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    position: fixed;
    transition: height 1500ms ease-in-out;
    background-color: $overlay;
    ::-webkit-scrollbar { width: 10px; }
	::-webkit-scrollbar-thumb { 
        background-color: #cccccc;
    }
}

.show-super-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide-super-modal {
	opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.container-new-document{
    min-height: 380px;
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {
    .super-body{
        max-height: 700px;
        // overflow-y: auto;
    }
}

/*    NOTEBOOKS, IPADS DEVICES    */

@media screen and (max-width: 1366px) {
    .super-body{
        max-height: 480px;
        // overflow-y: auto;
    }
    .huge{
        width: 75% !important;
        height: auto;
    }
}

/*    NOTEBOOKS, IPADS DEVICES    */

@media screen and (max-width: 992px) {

}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {    
    

}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {

}