/**
*   card-tables.scss
*   @description: Estilos de tarjetas de tablas
*/

.card-table {
    // height: 370px;
    border-radius: 4px;
    //padding: 0 $paddingMedium $paddingMedium $paddingMedium;
    background-color: $white;
    width: 100%;
    // box-shadow: 1px 0 20px rgba(0,0,0,.05);
    ::-webkit-scrollbar { width: 8px; }
    ::-webkit-scrollbar-thumb { background-color: #cccccc; }

    th {
        //padding: $paddingNormal 0;
        padding: 6px 0 1px 12px;
        border-bottom: 1px solid rgba($secondary, 0.4);
    }

    td {
        //padding:  8px 8px 8px 0px;
        padding: 1px 0px 1px 12px;
	}

    .acordeon-row {
        &:hover {
            background-color: $white;
        }
    }

    .row-without-border {
        th {
            border-bottom: none;
        }
    }

    .acordeon {
        padding: 8px 16px;
        border-bottom: 1px solid #9e9e9e61;
        border-right: 1px solid #9e9e9e61;
        border-left: 1px solid #9e9e9e61;
    }
	
	.th-options {
		width: 120px !important;
	}

	.th-options-mini {
		width: 50px !important;
    }

	.th-options-small {
		width: 94px !important;
    }
    
    .th-options-md {
		width: 180px !important;
	}

	.th-options-huge {
		width: 250px !important;
	}


    .sticky-th{
        background-color: $white ;

        th{
            position: sticky;
            background-color: #ffffff;
            top: 210px;
            z-index: 1;
            font-weight: 500;
            color: #737373;
        }
    }

    th {
        background-color: #ffffff;
        font-weight: 500;
        color: #737373;
    }

    thead {
        tr {
            &:hover {
                background-color: $white;
            }
        }
    }

    /*tbody tr:nth-child(even) {
        background-color: #f5f5f5;
    }*/

    tr {
		transition: 300ms ease-in-out;

		/*&.row-active {
			background-color: #c3c3c3;
		}*/
       
        &.row-active {
			background-color: #c3c3c35c;

            &:hover {
                background-color: #c3c3c35c;
            }
		}

        &:hover { 
			background-color: rgba(#f2f2f2, 0.6);
        }
    }

    .btn-secondary {
        transition: color 300ms ease-in-out;
        background-color: transparent;
        color: black;
        &:hover {
            background-color: transparent;
            color: $purple;
        }
    }

    /*button {
        transition: color 300ms ease-in-out;
        background-color: transparent;
        color: black;
        &:hover {
            background-color: transparent;
            color: $primary;
        }
    }*/
}

.card-table-teoric {
    // position: relative;
    /*.total-row {
        position: absolute;
        bottom: 0;
        width: 97%;
    }*/

    .total-budget {
        width: 171px;
    }

    .total-real {
        width: 205px;
    }

    .budget {
        width: 270px;
    }

}
.daily-invitations {
    display: flex;
    justify-content: space-between;
    .card-table-1 {
		width: 49%;
        // margin-right: $marginLarge;
        td {
            //padding: 8px 0;
            padding: 6px 0px 6px 12px;;
            border-bottom: 2px solid $light;

            .btn-extrasmall {
                padding: 0px 16px;
            }
        }
    }
    
    .card-table-2 {
        width: 40%;
        align-self: flex-start;
        td { border-bottom: 2px solid $light; }
	}

	.card-container {
		overflow-y: auto;
		min-height: 613px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
    }
    

    .card-event-container {
		overflow-y: auto;
		min-height: 370px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.card-table-guests {
    height: auto;
    max-height: initial;
}


.card-table-incidents {
    thead {
        .description {
            width: 30%;
        }        
    }
}


.card-table-bitacora {
    thead {
        .description {
            width: 25%;
        }        
    }

    p{
        color: $dark;
    }
}

.card-table-detail{
    overflow-y: scroll;
    max-height: 300px;
}

.card-table-acordeon {
    // height: 370px;
    border-radius: 4px;
    //padding: 0 $paddingMedium $paddingMedium $paddingMedium;
    background-color: $white;
    // box-shadow: 1px 0 20px rgba(0,0,0,.05);
    ::-webkit-scrollbar { width: 8px; }
    ::-webkit-scrollbar-thumb { background-color: #cccccc; }

    th {
        padding: 6px 0 1px 12px;
        border-bottom: 1px solid rgba($secondary, 0.4);
        position: relative;
        background-color: #ffffff;
        //top: 194px;
        top: 201px;
        z-index: 1;
        font-weight: 500;
        color: #737373;
    }

    tr {
		transition: 300ms ease-in-out;

		/*&.row-active {
			background-color: #c3c3c3;
		}*/
       
        &.row-active {
			background-color: #c3c3c35c;

            &:hover {
                background-color: #c3c3c35c;
            }
		}

        &:hover { 
			background-color: rgba(#f2f2f2, 0.6);
        }
    }

    .btn-secondary {
        transition: color 300ms ease-in-out;
        background-color: transparent;
        color: black;
        &:hover {
            background-color: transparent;
            color: $primary;
        }
    }

    td {
        //padding:  8px 8px 8px 0px;
        padding: 1px 0px 1px 12px;
	}

    .th-options {
		width: 120px !important;
	}

	.th-options-mini {
		width: 50px !important;
    }

	.th-options-small {
		width: 94px !important;
    }
    
    .th-options-md {
		width: 180px !important;
	}

	.th-options-huge {
		width: 250px !important;
	}
}