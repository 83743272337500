.tooltip {
	position: relative;
	// display: inline-block;
  
	.tooltiptext {
		visibility: hidden;
		width: auto;
		background-color: rgba(0, 0, 0, 0.776);
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 10px;
	
		/* Position the tooltip */
		position: absolute;
		z-index: 2;
		top: -5px;
		left: 80%;
	
		&::after {
			content: "";
			position: absolute;
			top: 27%;
			left: -28%;
			margin-left: -6.2px;
			border-width: 7px;
			border-style: solid;
			border-color: transparent rgba(0, 0, 0, 0.776) transparent transparent;
		}
	}

	.tooltiptext2 {
		visibility: hidden;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.776);
		text-align: center;
		border-radius: 6px;
		padding: 5px 3px;

		p {
			color: #fff;
			overflow: hidden;
			overflow-y: auto;
			max-height: 200px;
		}
	
		/* Position the tooltip */
		position: absolute;
		z-index: 2;
		left: 15px;
		top: -15px;
	
		&::after {
			content: "";
			position: absolute;
			top: 20px;
			left: -13px;
			border-width: 7px;
			border-style: solid;
			border-color: transparent rgba(0, 0, 0, 0.776) transparent transparent;
		}
	}
  
	&:hover .tooltiptext, &:hover .tooltiptext2 {
		visibility: visible;
	}
  }